const base64MongoId = require('base64-mongo-id');

export function encodeObjectId(hex: string): string {
  if (!hex || hex.length !== 24) return hex;
  return base64MongoId.toBase64(hex);
}

export function decodeObjectId(base64: string): string {
  if (!base64 || base64.length !== 16) return base64;
  return base64MongoId.toHex(base64);
}
