/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {IBook} from '@reedsy/schemas.editor-collections';
import {IBookContent} from '@reedsy/reedsy-sharedb/lib/common/book-content/book-content';
import {pick} from '@reedsy/utils.object';

export function readerBookInfo(book: IBook) {
  return {
    _id: book._id.toHexString(),
    ...pick(
      book,
      'uuid',
      'title',
      'subtitle',
      'coverUrl',
      'ownerId',
      'legacyMarketplaceId',
    ),
  };
}

export type IReaderBookInfo = ReturnType<typeof readerBookInfo>;
export type IReaderBook = {
  info: IReaderBookInfo;
  bookContents: IBookContent;
  orderNumbers: {
    [contentId: string]: number;
  };
};
