import {Attributor, BlotConstructor, Registry, Scope} from 'parchment';

import Break from '@reedsy/quill/blots/break';
import Container from '@reedsy/quill/blots/container';
import Cursor from '@reedsy/quill/blots/cursor';
import Inline from '@reedsy/quill/blots/inline';

import './blots/block';
import Callout from './blots/callout';
import IndentedBlockBlot from './blots/indented-block-blot';
import IndentedBlockquote from './blots/indented-blockquote-blot';
import Text from '@reedsy/quill/blots/text';
import ReedsyScroll from './blots/scroll';

import Bold from '@reedsy/quill/formats/bold';
import CodeBlock from './formats/code-block';
import {CodeBlockContainer} from '@reedsy/quill/formats/code';
import Italic from '@reedsy/quill/formats/italic';
import ReedsyListItem, {ReedsyListContainer} from './formats/list';
import Script from '@reedsy/quill/formats/script';
import Strike from '@reedsy/quill/formats/strike';
import Underline from '@reedsy/quill/formats/underline';

import ReedsyAlign from './formats/align';
import ReedsyHeader from './formats/header';
import TrackChange from './blots/track-changes/track-change';
import SoftBreakBlot from './blots/soft-break-blot';
import SceneBreaks from '@reedsy/studio.shared/services/quill/modules/scene-breaks';
import Figures from './modules/figures';
import TrackChanges from '@reedsy/studio.shared/services/quill/modules/track-changes';
import HighlightBlot from './blots/highlight';
import Links from './modules/links';

const BLOTS: any[] = [
  Break,
  Container,
  Cursor,
  HighlightBlot,
  Inline,
  IndentedBlockBlot,
  ReedsyScroll,
  SoftBreakBlot,
  Text,
];

// Track change must be the highest blot so that all changes are
// correctly grouped under one span
Inline.order.push(TrackChange.blotName);
CodeBlockContainer.allowedChildren.push(CodeBlock);

// Insert highlights right above links to prevent a bug with Safari
// See: https://github.com/reedsy/reedsy-editor/issues/2658
const linkIndex = Inline.order.indexOf('link');
Inline.order.splice(linkIndex + 1, 0, HighlightBlot.blotName);

const FORMATS: IFormatDefinitions = {
  align: [ReedsyAlign],
  bold: [Bold],
  blockquote: [IndentedBlockquote],
  callout: [Callout],
  'code-block': [CodeBlock, CodeBlockContainer],
  clean: [],
  italic: [Italic],
  header: [ReedsyHeader],
  list: [ReedsyListContainer, ReedsyListItem],
  script: [Script],
  strike: [Strike],
  underline: [Underline],
};

export default class ReedsyRegistry extends Registry {
  public static FORMATS: string[] = Object.keys(FORMATS);

  public static default(): ReedsyRegistry {
    return this.withFormats(null);
  }

  public static plainText(): ReedsyRegistry {
    return this.withFormats([]);
  }

  public static inline(): ReedsyRegistry {
    return this.withFormats([
      'bold',
      'clean',
      'italic',
      'script',
      'strike',
      'underline',
    ]);
  }

  public static all(): ReedsyRegistry {
    const registry = this.default();
    Figures.registerFormats(registry);
    SceneBreaks.registerFormats(registry);
    TrackChanges.registerFormats(registry);
    Links.registerFormats(registry);
    return registry;
  }

  public static withFormats(whitelist: string[]): ReedsyRegistry {
    const whitelistSet = new Set(whitelist || this.FORMATS);
    const registry = new this();

    BLOTS.forEach((blot) => registry.register(blot));

    this.FORMATS.forEach((formatName) => {
      if (whitelistSet.has(formatName)) {
        const formatClasses = FORMATS[formatName];
        formatClasses.forEach((formatClass) => registry.register(formatClass));
      }
    });

    return registry;
  }

  public override query(query: string | Node | Scope, scope: Scope = Scope.ANY): Attributor | BlotConstructor | null {
    if (!query) return null;
    return super.query(query, scope);
  }
}

interface IFormatDefinitions {
  [format: string]: any[];
}
