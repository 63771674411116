import Quill from '@reedsy/quill/core';
import ReedsyRegistry from '@reedsy/studio.shared/services/quill/registry';
import ReedsyTheme from './theme/reedsy-theme';
import Delta from '@reedsy/quill-delta';
import ReedsyScroll from './blots/scroll';
import debug from '@reedsy/studio.shared/utils/debug/debug';
import {isNoOp} from '@reedsy/utils.ot-rich-text';
import {INLINE_FORMATS} from './modules/toolbar/toolbar-container';
import {dig} from '@reedsy/utils.dig';
import TrackAttributor from '@reedsy/studio.shared/services/quill/blots/track-changes/attributors/track-attributor';
import trackedChangeMatcher from '@reedsy/studio.shared/services/quill/modules/clipboard/matchers/tracked-change-matcher';
import MODULES, {Modules, ModuleName} from './modules';
import {ModulesConfig, ModulesOptions} from './modules/module-options';
import {ContentEditable} from './helpers/content-editable';
import browser from '@reedsy/studio.shared/utils/browser';
import {EmitterSource} from '@reedsy/quill/core/emitter';
import ReedsyKeyboard from './modules/keyboard';
import {ObjectId} from '@reedsy/utils.object-id';
import {IReedsyOptionsMetadata} from './i-reedsy-options-metadata';

type QuillOptions = Quill['options'];
export type QuillConfig = ConstructorParameters<typeof Quill>[1];

interface IReedsyOptions {
  contentEditable?: ContentEditable;
  toolbarParent?: HTMLElement | string;
  metadata?: IReedsyOptionsMetadata;
}

export interface IReedsyQuillOptions extends Omit<QuillOptions, 'modules'>, IReedsyOptions {
  modules: ModulesOptions<Modules>;
}

export interface IReedsyQuillConfig extends
  Omit<QuillConfig, 'modules' | 'scrollingContainer' | 'bounds'>,
  IReedsyOptions {
  modules?: ModulesConfig<Modules>;
  scrollingContainer?: string | HTMLElement;
  bounds?: string | HTMLElement;
}

const INLINE_TOOLBAR_FORMATS = new Set(
  INLINE_FORMATS.formats.map((format) => typeof format === 'string' ? format : Object.keys(format)[0]),
);

class ReedsyQuill extends Quill {
  public override options: IReedsyQuillOptions;
  public override readonly scroll: ReedsyScroll;
  public override readonly keyboard: ReedsyKeyboard;

  private _blurIfOutside: (event: MouseEvent) => void;
  private _setMousedownTarget: (event: MouseEvent) => void;
  private mousedownTarget: EventTarget = null;

  public constructor(container: HTMLElement, options: IReedsyQuillConfig = {}) {
    options.registry ||= ReedsyRegistry.default();
    options.metadata ||= {};
    options.metadata.editorId ||= new ObjectId().toHexString();
    options.modules ||= {};

    if (!options.modules.clipboard || options.modules.clipboard === true) options.modules.clipboard = {};
    options.modules.clipboard.matchers ||= [];
    options.modules.clipboard.matchers.push(
      [`[${TrackAttributor.CHANGE_IDS_ATTRIBUTE}]`, trackedChangeMatcher],
    );

    options.modules.cursors ||= true;

    options.contentEditable ||= 'true';
    if (browser.isBrowser('firefox')) options.contentEditable = 'true';

    super(container, options as QuillConfig);

    // Re-enable to update contentEditable type
    if (this.isEnabled()) this.enable();

    // Fix an edge-case in Safari:
    // 1. Focus a Quill instance (eg chapter title)
    // 2. Click on an image in a *different* Quill instance (eg chapter content)
    // 3. First Quill instance retains focus, and can be typed in, etc.
    this._blurIfOutside = this.blurIfOutside.bind(this);
    this._setMousedownTarget = this.setMousedownTarget.bind(this);
    document.addEventListener('mousedown', this._setMousedownTarget);
    document.addEventListener('click', this._blurIfOutside);
  }

  public insertBlockEmbed(embed: string, value: any, source: EmitterSource = Quill.sources.API): void {
    const index = this.selection.savedRange.index;
    const [block, offset] = this.getLine(index);
    const endOfLine = offset === 0 ? index : index - offset + block.length();
    const delta = new Delta().retain(endOfLine).insert({[embed]: value});
    if (endOfLine === this.getLength()) delta.insert('\n');
    this.updateContents(delta, source);
    this.setSelection(delta.length());
  }

  public override setContents(delta: any[] | Delta, source?: EmitterSource): Delta {
    delta = new Delta(delta);
    const ops = delta.ops.filter((op) => {
      if (typeof op.insert === 'string') return true;
      const embed = Object.keys(op.insert)[0];
      return !!this.scroll.query(embed);
    });
    return super.setContents(ops, source);
  }

  @debug()
  public override updateContents(delta: any[] | Delta, source?: EmitterSource): Delta {
    return super.updateContents(delta, source);
  }

  public override format(name: string, value: any, source?: EmitterSource): Delta {
    let change = super.format(name, value, source);
    // If applying a toolbar inline format was a no-op, then we probably meant to do the
    // opposite format. This can happen for example in the following case:
    // 1. Have some text with an endnote in the middle
    // 2. Highlight the text
    // 3. Make the text bold
    // 4. Make the text un-bold
    // 5. The text isn't unbolded, because the endnote can't be bold (so Quill tries to
    //    apply bold formatting again)
    const selection = this.getSelection();
    if (dig(selection, 'length') && isNoOp(change) && INLINE_TOOLBAR_FORMATS.has(name)) {
      change = super.format(name, false, source);
    }
    return change;
  }

  public override getModule<M extends ModuleName>(name: M): InstanceType<(typeof MODULES)[M]> {
    return super.getModule(name) as any;
  }

  public override enable(enabled = true): void {
    const contentEditable = enabled ? this.options.contentEditable : false;
    this.scroll.enable(contentEditable);
  }

  private setMousedownTarget(event: MouseEvent): void {
    this.mousedownTarget = event.target;
  }

  private blurIfOutside(): void {
    if (!this.root.isConnected) {
      document.removeEventListener('click', this._blurIfOutside);
      document.removeEventListener('mousedown', this._setMousedownTarget);
      return;
    }

    if (this.container.contains(this.mousedownTarget as Node)) return;
    if (this.hasFocus()) this.blur();
  }
}

ReedsyQuill.debug('error');

(window as any).Quill = ReedsyQuill;

const modules: any = {};
for (const key in MODULES) {
  modules[`modules/${key}`] = MODULES[key as ModuleName];
}

ReedsyQuill.register({
  ...modules,
  'themes/reedsy': ReedsyTheme,
});

export default ReedsyQuill;
