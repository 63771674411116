<template>
  <EndnotePaneWrapper
    :endnote-number="endnoteNumber"
    :show="isOpen"
    read-only
    class="endnotes-wrapper"
  >
    <template #endnote-editor>
      <div id="endnote-container">
        <template v-if="isLoading">
          <SkeletonLoader
            v-for="index in 4"
            :key="index"
            class="paragraph-skeleton"
          />
        </template>
        <RichTextReader
          v-else
          id="endnote-editor"
          ref="editor"
          :rich-text-id="endnoteId"
          :options="quillOptions"
          :load-endnotes="false"
        >
          <template #empty-placeholder>
            <p class="empty-endnote-text theme-body">
              This endnote is still a work in progress
            </p>
          </template>
        </RichTextReader>
      </div>
    </template>
  </EndnotePaneWrapper>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import RichTextReader from '@reedsy/studio.viewer/components/reader/rich-text-reader.vue';
import ReedsyRegistry from '@reedsy/studio.shared/services/quill/registry';
import {endnoteIdFromHash} from '@reedsy/studio.shared/utils/endnote-hash';
import Overlay from '@reedsy/studio.shared/components/overlay.vue';
import EndnotePaneWrapper from '@reedsy/studio.shared/components/endnote-pane/endnote-pane-wrapper.vue';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {IBookViewerQuillConfig} from '@reedsy/studio.viewer/services/quill';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerRichTextModule} from '@reedsy/studio.viewer/store/modules/rich-text/book-viewer-rich-text';

@Component({
  components: {
    Overlay,
    RichTextReader,
    EndnotePaneWrapper,
    SkeletonLoader,
  },
})
export default class EndnotePane extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerRichText)
  public $richText: BookViewerRichTextModule;

  public get endnoteId(): string {
    return endnoteIdFromHash(this.$route.hash);
  }

  public get isOpen(): boolean {
    return !!this.endnoteId;
  }

  public get isLoading(): boolean {
    return this.$richText.isEndnoteLoading(this.endnoteId);
  }

  public get endnoteNumber(): number {
    return this.$richText.endnotesNumber[this.endnoteId] || 1;
  }

  public get quillOptions(): IBookViewerQuillConfig {
    return {
      placeholder: 'This endnote is still in progress...',
      bounds: '#endnote-pane-content',
      toolbarParent: '#endnote-container',
      registry: ReedsyRegistry.inline(),
    };
  }
}
</script>

<style lang="scss" scoped>
#endnote-container {
  overflow-y: auto;
  width: 100%;

  .paragraph-skeleton {
    width: 40rem;
  }
}
</style>
