import {Scope} from 'parchment';
import Op from '@reedsy/quill-delta/dist/Op';
import ReedsyRegistry from '@reedsy/studio.shared/services/quill/registry';

export function isBlock(op: Op): boolean {
  if (!op || !op.insert) return false;
  if (typeof op.insert === 'string') return op.insert.startsWith('\n');
  const embed = Object.keys(op.insert)[0];
  return !!ReedsyRegistry.all().query(embed, Scope.BLOCK);
}
