import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import Image from '@/assets/icons/image-file.svg';

export default class ImageFile extends JsxComponent {
  public render(): HTMLElement {
    return (
      <span
        dangerouslySetInnerHTML={Image}
        class="vui-icon"
      />
    );
  }
}
